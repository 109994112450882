<template>
	<div class="solo-complex">
		<div class="container">
			<Breadcrumb :pages="pages" class="solo-complex__breadcrumb" />
			<Images v-if="!isLoad" :images="imgList" class="solo-complex__images" />
			<or-skeleton v-else class="solo-complex__images--skeleton" />

			<Title
				:is-load="isLoad"
				:content="complexInfo"
				class="solo-complex__block"
			/>
			<Immobilien
				:is-load="isLoad"
				:content="complexInfo"
				:address="address"
				type="project"
				class="solo-complex__block"
			/>

			<Showcase
				v-if="!isLoad"
				:content="complexInfo"
				class="solo-complex__block solo-complex__block--showcase"
			/>
			<or-skeleton v-else class="solo-complex__block--skeleton-showcase" />

			<template v-if="!isLoad">
				<KindsHousing
					v-if="complexInfo.announcement_types?.length"
					class="solo-complex__block solo-complex__block--tags"
					:title="$t('mobile.complex_kinds_housing')"
					type="complex"
					:project="{
						title: complexInfo.title,
						slug: complexInfo.slug,
						region: complexInfo.region
					}"
					:content="complexInfo.announcement_types"
				/>
			</template>
			<or-skeleton v-else class="solo-project__complex--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="featuresList?.length"
					:title="$t('ads.features')"
					:content="featuresList"
					class="solo-complex__block solo-complex__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-complex__block--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="externalInfrastructureList?.length"
					:title="$t('ads.externalInf')"
					:content="externalInfrastructureList"
					class="solo-complex__block solo-complex__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-complex__block--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="internalInfrastructureList?.length"
					:title="$t('ads.iternalInf')"
					:content="internalInfrastructureList"
					class="solo-complex__block solo-complex__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-complex__block--skeleton" />

			<template v-if="!isLoad">
				<Rooms
					v-if="mapPlan?.length"
					class="solo-complex__block"
					:plan="mapPlan"
				/>
			</template>
			<or-skeleton v-else class="solo-complex__block--skeleton-rooms" />

			<template v-if="!isLoad">
				<SpecialOffers
					v-if="paymentInfo?.length"
					:loanOffersList="paymentInfo"
					class="solo-complex__block"
				/>
			</template>
			<or-skeleton v-else class="solo-complex__block--skeleton-offers" />

			<Gallery
				v-if="adsList?.length"
				:title="$t('general.popularAds')"
				:content="adsList"
				:options="{ alt: 'Photo apartments' }"
				card="EstateCard"
				@cardClick="redirect"
			/>
		</div>
	</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { i18n } from '@/i18n'
import { setCommonMetaData } from '@/utils/setMetaData'
import { getDateInFormat } from '@/utils/dateTime.js'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

import Breadcrumb from '@/components/Breadcrumb'
import Images from '@/components/SoloPages/Images'
import Title from '@/components/SoloPages/TitleLine'
import Immobilien from '@/components/SoloPages/Immobilien'
import Showcase from '@/components/SoloPages/Showcase'
import KindsHousing from '@/components/SoloPages/KindsHousing'
import Tags from '@/components/SoloPages/Tags'
import Rooms from '@/components/SoloPages/Rooms'
import SpecialOffers from '@/components/SoloPages/SpecialOffers'

import Gallery from '@/components/Gallery.vue'

export default {
	name: 'SoloComplex',
	components: {
		Breadcrumb,
		Images,
		Title,
		Immobilien,
		Tags,
		Rooms,
		Showcase,

		SpecialOffers,
		KindsHousing,
		Gallery
	},
	setup() {
		const complexInfo = ref({})
		const adsList = ref([])
		const isLoad = ref(true)

		const router = useRouter()
		const route = useRoute()
		const store = useStore()

		const mapPlan = computed(() =>
			complexInfo.value?.plans_without_studio?.length > 0 ||
			complexInfo.value?.plans_with_studios?.length > 0
				? [
						...complexInfo.value?.plans_without_studio,
						...complexInfo.value?.plans_with_studios
				  ]
				: []
		)
		const pages = computed(() => ({
			first: {
				title: i18n.global.t('general.complexes'),
				srcName: 'Complexes'
			},
			past: i18n.global.t('general.complexes')
		}))
		const featuresList = computed(
			() =>
				complexInfo.value?.amenity?.filter((el) => el.type === 'features')[0]
					?.name
		)
		const externalInfrastructureList = computed(
			() =>
				complexInfo.value?.amenity?.filter((el) => el.type === 'exterenal')[0]
					?.name
		)
		const internalInfrastructureList = computed(
			() =>
				complexInfo.value?.amenity?.filter((el) => el.type === 'internal')[0]
					?.name
		)
		const address = computed(() => ({
			...complexInfo.value.location,
			region: { ...complexInfo.value.region },
			city: { ...complexInfo.value.city }
		}))

		const cardInfo = computed(() => [
			{
				title: i18n.global.t('ads.stagesTitle'),
				text: complexInfo.value?.date_complete
					? getDateInFormat(
							complexInfo.value?.date_complete,
							store.state.uiLanguage
					  )
					: null
			},
			{
				title: complexInfo.value?.project?.title,
				text: i18n.global.t('general.project'),
				linkName: 'SoloProject',
				linkSlug: complexInfo.value?.project?.slug
			},
			{
				title: complexInfo.value?.developer?.title,
				text: i18n.global.t('general.developer'),
				linkName: 'SoloDeveloper',
				linkSlug: complexInfo.value?.developer?.slug
			}
		])
		const imgList = computed(() => {
			if (complexInfo.value?.images)
				return [
					...complexInfo.value?.images,
					...complexInfo.value?.images_interior
				]
			else return []
		})
		const paymentInfo = computed(() =>
			complexInfo.value?.loan_offers?.filter((el) => el.title && el.description)
		)

		const redirect = (event, slug) => {
			if (event.target.type !== 'button' && event.target.tagName !== 'I')
				router.push({
					name: 'SoloAds',
					params: { slug: slug.slug, lang: store.state.uiLanguage }
				})
		}

		const get = () => {
			createRequest(requestConfigs.GETComplex, {
				routerPayload: { slug: route.params.slug /*"olympus"*/ }
			})
				.then((result) => {
					complexInfo.value = result.response

					setCommonMetaData(
						`${i18n.global.t('titlePages.complex', {
							title: complexInfo.value.title,
							region: complexInfo.value.region.title
						})} - OWRealty`,
						{
							description: i18n.global.t('meta.complexDesc'),
							type: 'article',
							title: `${i18n.global.t('titlePages.complex', {
								title: complexInfo.value.title,
								region: complexInfo.value.region.title
							})} - OWRealty`,
							url: window.location.href
						}
					)
				})
				.catch((err) =>
					store.commit('setError', { status: err.errCode || 404 })
				)
				.finally(() => (isLoad.value = false))
			createRequest(requestConfigs.GETAdsSearch, {
				queryPayload: {
					complex: route.params.slug
				}
			}).then((result) => (adsList.value = result.response.items))
		}

		get()

		return {
			complexInfo,
			adsList,
			isLoad,

			mapPlan,
			imgList,
			address,
			pages,
			featuresList,
			externalInfrastructureList,
			internalInfrastructureList,
			paymentInfo,
			cardInfo,

			redirect,

			carouselSettings: {
				settings: {
					itemsToShow: 4,
					snapAlign: 'start',
					itemsToScroll: 4,
					wrapAround: false
				},
				breakpoints: {
					320: { itemsToShow: 1 },
					460: { itemsToShow: 2 },
					920: { itemsToShow: 3, touchDrag: true },
					1100: { itemsToShow: 4, itemsToScroll: 0, touchDrag: false }
				}
			}
		}
	}
}
</script>

<style lang="scss">
.solo-complex {
	padding-bottom: 50px;

	&__breadcrumb {
		margin-bottom: 30px;
	}

	&__images {
		margin-bottom: 40px;

		&--skeleton {
			height: 380px;
			width: 100%;
			margin-bottom: 40px;

			@media screen and (max-width: 768px) {
				height: 300px;
			}
		}
	}

	&__block {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&--skeleton {
			width: 100%;
			height: 110px;
			margin-bottom: 30px;
		}

		&--skeleton-showcase {
			width: 100%;
			height: 80px;
			margin-bottom: 30px;
		}

		&--skeleton-rooms {
			width: 100%;
			height: 490px;
			margin-bottom: 30px;
		}

		&--skeleton-offers {
			width: 100%;
			height: 210px;
		}

		&--tags {
			padding-bottom: 20px;
		}

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 40px;

		&__block {
			&--showcase {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}
}
</style>
